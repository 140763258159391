import React from "react";
import Layout from "../layouts/en";
import Seo from "../components/info-site/Seo";
// import Stage from "../components/info-site/Stage";
// import HomepageHowItWorks from "../components/info-site/HomepageHowItWorks";
// import StageContent from "../components/info-site/StageContent";

// import BrandPartnerships from "../components/info-site/BrandPartnerships";
// import SideContentWithImg from "../components/info-site/SideContentWithImg";

// import gapMainFeatures from "../data/gapMainFeatures";
// import GetQuote from "../components/info-site/GetQuote";
// import BiggestChallanges from "../components/info-site/BiggestChallanges";

// import styles from "../styles/tyviso-gap.module.css"
import ProductHeader from "../components/info-site/ProductHeader";
import ProductBenefits from "../components/info-site/ProductBenefits";
import ProductHowItWorks from "../components/info-site/ProductHowItWorks";
import GetStarted from "../components/info-site/GetStarted";

import EE from '../assets/ee-black.png'
import Wild from '../assets/wild-black.png'
import SS from '../assets/secret-sales.png'
import PlusNet from '../assets/plusnet-black.png'

import ProdBenefits1 from '../assets/product-benefits-2.png'
import ProdBenefits2 from '../assets/product-benefits-6.png'
import ProdBenefits3 from '../assets/product-benefits-4.png' 
import ProdBenefits4 from '../assets/product-benefits-5.png'
import ProdBenefits5 from '../assets/product-benefits-7.png'

import PartnersLogoImg from '../assets/partners-logo-tr.png'
import ClaimGiftImg from '../assets/claim-gift-tr.png'
import FreeGift from '../assets/product-gap-2.png'

const TyvisoGAP = props => {
    return (
			<Layout location={props.location}>
				<Seo titleId="gapTitle" descriptionId="gapDescription" />

        <ProductHeader
					title="gapProductHeaderTitle"
					desc="gapProductHeaderDesc"
          list={[
            'One click activation',
            'No monthly fees',
            'No setup fees',
          ]}
          trusted="Trusted by 500+ brands"
          img={FreeGift}
					partners={[
						{
							id: 'ee',
							img: EE,
						},
						{
							id: 'wild',
							img: Wild,
						},
						{
							id: 'ss',
							img: SS,
						},
						{
							id: 'plusnet',
							img: PlusNet,
						},
					]}
				/>

        <ProductBenefits
					title="gapProductBenefitsTitle"
          list={[
            {
              icon: ProdBenefits1,
              text: 'Add customer value on a typically underutilised page'
            },
            {
              icon: ProdBenefits2,
              text: 'Increase chance of of re-purchase'
            },
            {
              icon: ProdBenefits3,
              text: 'Drive positive customer sentiment'
            },
            {
              icon: ProdBenefits4,
              text: 'Show previews pre-purchase to increase CRO'
            },
            {
              icon: ProdBenefits5,
              text: 'Increase reciprocal swaps with other brands'
            }
          ]}
        />

        <ProductHowItWorks
					title="gapProductHowItWorksTitle"
          list={[
            {
              img: PartnersLogoImg,
              title: '1. Select',
              text: 'Choose from existing and exclusive offers of who you want to partner with'
            },
            {
              img: ClaimGiftImg,
              title: '2. Push',
              text: 'Drop & drag  effortlessly on your site using our tech in 30 seconds '
            },
            {
              img: FreeGift,
              title: '3. Engage',
              text: 'Watch your customers interact and your revenue catapult'
            },
          ]}
        />
        <GetStarted />
				{/* <Stage
					title="gapTitle"
					icon
					withoutMedia
					classTitle={styles.title}
					// noWrapTitle
				>
					<StageContent
						title="gapSideTitle"
						ctaLink="https://rtbmedia.hubspotpagebuilder.com/book-a-demo"
						ctaText="homepageStagePrimaryCta"
						// wideText
						list={[
							'gapSideListItemOne',
							'gapSideListItemTwo',
							'gapSideListItemThree',
						]}
					/>
				</Stage>

				<HomepageHowItWorks
					title="gapHowItWorksTitle"
					steps={[
						{
							id: 'gapHowItWorksOne',
							icon: 'blue',
						},
						{
							id: 'gapHowItWorksTwo',
							icon: 'turquoise',
						},
						{
							id: 'gapHowItWorksThree',
						},
					]}
				/>

				<BrandPartnerships title="mainFeatures">
					{gapMainFeatures.map(({ id, ...rest }) => (
						<SideContentWithImg lightParagraph key={id} {...rest} />
					))}
				</BrandPartnerships>

				<BiggestChallanges
					title="goals"
					centered
					cards={[
						{
							id: 'cpa',
							link: '/your-goals/acquire-new-customers',
							text: 'biggestChallangesCardOneTitle',
						},
						{
							id: 'ltv',
							link: '/your-goals/raise-customer-lifetime',
							text: 'biggestChallangesCardTwoTitle',
						},
						{
							id: 'revshare',
							link: '/your-goals/monetise-your-ecommerce',
							text: 'biggestChallangesCardThreeTitle',
						},
						{
							id: 'cro',
							link: '/your-goals/uplift-basket-completion',
							text: 'biggestChallangesCardFourTitle',
						},
					]}
				/>

				<GetQuote
					title="gapQuoteTitle"
					desc="gapQuoteDesc"
					cta="gapQuoteCta"
				/> */}
			</Layout>
		);
};

export default TyvisoGAP;